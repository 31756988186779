.column-count {
  margin-bottom: 2em;
  @include breakpoint (medium) {
    column-count: 2;
    column-gap: 30px;
  }
}


.grid-container {
  padding: 0;
}

