.h-fixed-top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  z-index: 99;
  transition: all .2s ease-in-out;
  // &.opaque {
  //   @include shadow(0 2px 7px 0 rgba($black,.1));
  // }
}
