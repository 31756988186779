.hamburger-container {
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 0;
  transition: top .2s ease-in-out;
  transform: translateY(-50%);
  #hambg {
    width: rem-calc(68);
    height: rem-calc(136);
    opacity: 1;
    transition: opacity .10s ease-in-out;
    &.is-active {
      opacity: 0;
    }
  }
  @include breakpoint(small only) {
    &.opaque {
      top: 88%;
    }
  }
}
.hamburger {
  position: absolute;
  left: 0;
  top: rem-calc(54);
  @include breakpoint(medium) {

  }
  @include breakpoint(large) {

  }
  button {
    position: relative;
    width: rem-calc(30);
    height: rem-calc(30);
    display: block;
    padding: 0;
    margin: 0;
    cursor: pointer;
    background-color: transparent !important;
  }
  span {
    display: block;
    background-color: #6c6c6d;
    position: absolute;
    width: rem-calc(30);
    left: rem-calc(8);
    height: rem-calc(2);
    top: 0;
    opacity: 1;
    transition: all .2s ease-in-out;
    + span {
      top: rem-calc(12);
      + span {
        top: rem-calc(24);
      }
    }
  }
  &.is-active {
    span {
      top: rem-calc(11);
      transform: rotate(45deg);
      background-color: $white;
      + span {
        opacity: 0;
        + span {
          opacity: 1;
          transform: rotate(-45deg);
        }
      }
    }
  }
  &:hover {
    cursor: pointer;
  }
}
