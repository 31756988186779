//global font-size (small, medium, large)
// main {
//   font-size: rem-calc(18);
//   @include breakpoint(medium) {
//     font-size: rem-calc(21);
//   }
//   @include breakpoint(large) {
//     font-size: rem-calc(24);
//   }
// }

main {
  //Dynamic Font Size
  @include font-size(1,1.25);
  p {
    line-height: 1.5;
  }
}

.lead {
  @include font-size(1.25,1.5625);
  line-height: 1.2;
}

h1, h2, .quote {
  @include font-size(1.875,3.125);
  margin-bottom: 0.5em;
  line-height: 1.2;
}

a:focus {
  outline: none;
}

hr {
  width: rem-calc(100);
  border-width: 2px;
  border-color: $white;
  margin: 3em auto 3em auto;
}

.impressum a {
  color: $red;
}