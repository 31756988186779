html.sr {
  .fadeinbottominterval, .fadeinbottom {
    visibility: hidden;
  }
}

.relative {
  position: relative;
}



.full-link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}

.c-yellow {
  color: $yellow;
}
.c-green {
  color: $greentxt;
}
.c-blue {
  color: $bluetxt;
}
.c-white {
  color: $white;
  a {
    color: $white;
  }
}

.bg-white {
  background-color: $white;
}
.bg-green {
  background-color: $green;
}

.bg-red {
  background-color: $red;
}

.bg-blue {
  background-color: $blue;
}

.bg-beige {
  background-color: $beige;
}

.gap-right {
  @include breakpoint(medium) {
    padding-right: rem-calc(15);
  }
  @include breakpoint(large) {
    padding-right: rem-calc(25);
  }
}
.gap-left {
  @include breakpoint(medium) {
    padding-left: rem-calc(15);
  }
  @include breakpoint(large) {
    padding-left: rem-calc(25);
  }
}

.small-margin-bottom {
  @include breakpoint(small only) {
    margin-bottom: rem-calc(10);
  }
}

.flex-parent {
  display: flex;

}