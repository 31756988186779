img {
  image-rendering: -webkit-optimize-contrast;
}
.combi-figure {
  position: relative;
  display: block;
  margin-bottom: rem-calc(10);
  &.two-columns-left {
    margin-right: rem-calc(5);
  }
  &.two-columns-right {
    margin-left: rem-calc(5);
  }
  img {
    width: 100%;
  }
}

.main-image {
  width: rem-calc(1920);
  max-width: 100%;
  height: 100vh;
  margin: 0 auto;
  padding: rem-calc(10);
  @include breakpoint(medium) {
    padding: rem-calc(30);
  }
  @include breakpoint(large) {
    padding: rem-calc(50);
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

