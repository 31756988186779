.button {
  border-radius: 3em;
}

.btn-negative {
  border: 2px solid $white;
  background-color: $blue;
  color: $white;
  &:hover {
    background-color: $white;
    color: $blue;
  }
}


.circle {
  border-radius: 50%;
  background: $red;
  color: $white;
  font-weight: bold;
  font-size: rem-calc(11);
  width: rem-calc(120);
  height: rem-calc(120);
  text-transform: uppercase;
  line-height: 1.1;
  .date {
    font-weight: normal;
    display: inline-block;
    margin-bottom: 0.2em;
  }
  .big {
    font-size: 1.8em;
  }
  @include breakpoint (large) {
    font-size: rem-calc(21);
    width: rem-calc(220);
    height: rem-calc(220);
  }
  .circle-txt {
    text-align: center;
    position: relative;
    line-height: 1.2;
    top: 48%;
    transform: translateY(-50%);
  }
}


.main-image, figure {
  position: relative;
  .circle {
    position: absolute;
    bottom: rem-calc(30);
    right: rem-calc(20);
    z-index:7;
    @include breakpoint (medium) {
      bottom: rem-calc(60);
      right: rem-calc(60);
    }
    @include breakpoint (large) {
      bottom: rem-calc(100);
      right: rem-calc(100);
    }
    &--big {
      right: rem-calc(20);
      @include breakpoint (medium) {
        right: rem-calc(60);
      }
      @include breakpoint (large) {
        right: rem-calc(100);
      }
    }
  }

}

.main-image .arrowdown {
  position: absolute;
  bottom: rem-calc(10);
  left: 0;
  right: 0;
  z-index: 9;
  text-align: center;
  cursor: pointer;
  @include breakpoint (medium) {
    bottom: rem-calc(30);
  }
  @include breakpoint (large) {
    bottom: rem-calc(50);
  }
  img {
    width: rem-calc(136);
    height: auto;
  }
}

.to-top {
  position: fixed;
  top: 50%;
  right: 0;
  cursor: pointer;
  transform: translateY(-50%);
  opacity: 0;
  transition: all .2s ease-in-out;
  z-index: -1;
  &.opaque {
    opacity: 1;
    z-index: 9;
  }
  @include breakpoint(small only) {
    top: 88%;
  }
}

.arrow-right  {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  border: none;
  height: rem-calc(136);
  width: rem-calc(68);
  transition: opacity .2s ease-in-out;
  opacity: 1;

  > a > img {
    position: relative;
    object-fit: none;
    height: rem-calc(136);
    width: rem-calc(68);
  }
  &.opaque {
    opacity: 0;
  }
}