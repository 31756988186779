ul:not(.menu):not(.orbit-container):not(.kroki-list) {
  margin-left: rem-calc(14);
  padding-left: 0;
  list-style-type: none;
  li {
    &:before {
      content: "– ";
      margin-left: rem-calc(-14);
      margin-right: 0;
      padding-right: rem-calc(3);
    }
  }
}



ol.olcircle {
  counter-reset: my-awesome-counter;
  list-style: none;
  padding-left: 35px;
  margin-left: 0;
  margin-bottom: 3.5em;
  font-size: rem-calc(15);
  @include breakpoint (large) {
    margin-bottom: 5.5em;
  }
  li {
    margin: 0 0 0.8em 0;
    counter-increment: my-awesome-counter;
    position: relative;
    margin-bottom: rem-calc(16);
    @include breakpoint (large) {
      float: left;
      margin-right: 55px;
    }
    &::before {
      content: counter(my-awesome-counter);
      font-weight: bold;
      position: absolute;
      font-size: 1.2em;
      left: calc(-1 * 10px - 22px);
      width: 24px;
      height: 24px;
      line-height: 1.25;
      top: 0;
      background: $white;
      border-radius: 50%;
      text-align: center;
    }
  }

}

.files {
  margin-top: 2em;
  a {
    display: grid;
    border-bottom: 2px solid $white;
    padding: rem-calc(8);
    grid-template-columns: minmax(0, 1fr) auto;
    @include breakpoint (large) {
      font-size: rem-calc(18);
    }
    .svg-icon {
      font-size: 0.6em;
      .icon {
        fill: $white;
      }
    }
    &:hover {
      background-color: rgba($white, 0.2);
    }
  }
}

.kroki-list {
  @include breakpoint(medium only) {
    column-count: 2;
  }
  @include breakpoint (large) {
    font-size: rem-calc(18);
  }
  margin-left: 0;
  margin-bottom: 2em;
  list-style-type: none;
  li {
    padding: rem-calc(5 15);
    border-bottom: 2px solid $white;
    span {
      display: inline-block;
      margin-right: rem-calc(20);
    }
    &:last-child {
      border: none;
    }
  }
}
