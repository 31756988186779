main {
  padding: 0;
  overflow: hidden;
  padding: rem-calc(0 10);
  @include breakpoint(medium) {
    padding: rem-calc(0 30);
  }
  @include breakpoint(large) {
    padding: rem-calc(0 50);
  }
}
