.box-image {
  width: 100%;
  height: 100%;
  @include breakpoint(medium) {
    position: absolute;
  }
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.table-box {
  padding: 2em;
  color: $white;
  width: 100%;
  @include breakpoint(medium) {
    padding: 0 2em;
    margin: 2em 0;
    border-left: 2px solid $white;
    z-index: 1;
  }
  @include breakpoint(large) {
    padding: 0 3em;
    margin: 3em 0;
    aspect-ratio: 1.1;
    display: flex;
  }
  > div {
    width: 100%;
  }
}

.text-box {
  display: flex;
  position: relative;
  padding: 2em;

  @include breakpoint(medium) {

  }
  @include breakpoint(large) {
    padding: 3em;
  }
  @include breakpoint(xlarge) {
    aspect-ratio: 1;
  }

  &.no-aspect {
    aspect-ratio: auto;
  }
}

.more {
  display: block;
  text-align: right;
  .svg-icon {
    vertical-align: -0.5em;
    margin-left: 0.2em;
    .icon {
      fill: $white;
    }
  }
}

.map-legend {
  padding: 2em 2em 0 2em;
  min-height: 100%;
  @include breakpoint(large) {
    padding: 0 3em;
    margin: 3em 0;
    border-right: 2px solid $white;
  }
}
.local-map {
  padding: rem-calc(5);
  @include breakpoint(medium) {
    padding: 2em;
  }
  @include breakpoint(large) {
    padding: 2.5em;

  }
}