table {
  margin-bottom: 3.5em;
  @include breakpoint (small only) {
    font-size: rem-calc(15);
  }
  thead {
    color: $white;
  }
  tbody td, thead th {
    padding: rem-calc(4 8);
    &:first-child {
      padding-left: 2px !important;
    }
  }
}


.table.dataTable {
  font-size: rem-calc(15);
  @include breakpoint (small only) {
    font-size: rem-calc(13);
  }
  line-height: 1;
  border-collapse: collapse;
  position: relative;
  margin-bottom: 3.5em;
  thead {
    background-color: transparent;
    border: none;
    tr {
      th {
        padding: rem-calc(15 5 15 5);
        text-align: left;
        position: sticky;
        top: -3px;
        color: $white;
        font-weight: bold;
        &:first-child {
          width: auto !important;
        }
        span {
          white-space: nowrap;
        }
      }
    }
  }
  tbody {
    background-color: transparent;
    border: none;
    tr {
      border: none;
      border-top: 2px solid $white;
      background-color: transparent;
      &:hover {
        background-color: rgba($white,0.2);
      }
      &:focus {
        background-color: rgba($white,0.2);
        outline: none;
      }
      td {
        cursor: pointer;
        padding: rem-calc(15 5 15 5);
        text-align: left;
        white-space: nowrap;
        font-weight: normal;
        color: $white;
        @include breakpoint (small only) {
          padding: rem-calc(14 3 10 3);
        }
        .icon-pdf {
          width: 18px;
          height: 22px;
          fill: $white;
          @include breakpoint (small only) {
            width: 16px;
            height: 20px;
          }
        }
      }
      td:first-of-type {
        > span {
          display: inline-block;
          vertical-align: middle;
          text-transform: lowercase;
          padding-left: 2px;
        }
      }
      td:last-of-type {
        text-align: center;
        > a {
          line-height: 1;
          font-size: 0;
          color: var(--object-color);
          display: block;
        }
      }
      &.sold td {
        opacity: 0.6;
      }
    }
  }
}

//scroll table horizontal
.dataTables_wrapper {
  //max-height: rem-calc(530);
  overflow: auto;
}

//hide arrows for sorting
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc {
  background-image: none;
}

//show border-bottom for sorting
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc {
  span {
    //border-bottom: 1px solid $black;
  }
}

.whgimage {
  margin-bottom: rem-calc(20);
  @include breakpoint (large) {
    padding-top: rem-calc(20);
  }
}



#whgnB tbody tr:focus {
  background-color: $green;
  border-left: 1px solid $green;
  border-right: 1px solid $green;
}

.table-head {
  font-size: rem-calc(20);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: flex-start;
  @include breakpoint (medium) {
    font-size: rem-calc(41);
  }
  i {
    font-style: normal;
    order: 0;
    flex: 0 1 auto;
    align-self: flex-end;
    @include breakpoint (medium) {

    }
  }
  span {
    font-size: rem-calc(15);
    text-transform: none;
    font-family: $body-font-family;
    order: 0;
    flex: 0 0 auto;
    align-self: flex-end;
    padding-bottom: 7px;
    @include breakpoint (medium) {
      font-size: rem-calc(18);
    }
  }
}

.table-sub {
  padding: 1em 0;
}


.region-table {
  background-color: transparent;
  thead {
    font-weight: bold;
    background-color: transparent;
    border: none;
    tr {
      th {
        border-right: 2px solid $white;
        padding: .2em 7%;
        &:last-child {
          border: none;
        }
      }
    }
  }
  tbody {
    background-color: transparent;
    border: none;
    tr {
      &:nth-child(even) {
        background-color: transparent;
      }
      td {
        border-right: 2px solid $white;
        padding: .2em 7%;
        &:last-child {
          border: none;
        }
      }
    }
  }
}