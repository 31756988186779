footer {
  background-color: $white;
  color: $red;
  padding: rem-calc(20 10 10 10);
  @include breakpoint(medium) {
    padding: rem-calc(30);
  }
  @include breakpoint (large) {
    font-size: rem-calc(15);
    padding: rem-calc(50);
  }
  @include breakpoint (1360) {
    font-size: rem-calc(16);
  }
  .footer-logo {
    //width: rem-calc(116);
    //display: block;
    //margin-bottom: rem-calc(30);
    //@include breakpoint (medium) {
    //  width: rem-calc(178);
    //}
    //@include breakpoint (large) {
    //  margin-bottom: 0;
    //  margin-right: rem-calc(10);
    //}
    @include breakpoint(1700) {
      width: rem-calc(290);
    }
    @include breakpoint(1700) {
      width: rem-calc(290);
    }
  }
  p {
    @include breakpoint (large) {
      margin-bottom: 0;
    }
    @include breakpoint (1400) {
      margin-right: rem-calc(10);
    }
    @include breakpoint (1500) {
      margin-right: rem-calc(30);
    }
  }
  //span {
  //  display: block;
  //  @include breakpoint (large) {
  //    display: inline-block;
  //  }
  //}
  address {
    margin-bottom: rem-calc(25);
    @include breakpoint (large) {
      margin-bottom: 0;
    }
  }
  a {
    color: $red;
    text-decoration: none;
    &:hover {
      color: $black;
      text-decoration: none;
    }
  }
}


.social-footer {
  padding-top:  7px;
  .svg-icon {
    width: 3em;
    height: 3em;
    .icon {
      fill: $red;
    }
  }
}