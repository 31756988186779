.orbit {
  .orbit-bullets {
    position: absolute;
    right: 10px;
    bottom: 0;
    @include breakpoint(large) {
      right: 20px;
      bottom: 5px;
    }
    button {
      opacity: 0.6;
      background: transparent;
      border-radius: 0;
      cursor: pointer;
      transition: opacity .15s ease-in-out;
      &.is-active {
        opacity: 1;
      }
    }
  }
  .orbit-caption {
    padding: rem-calc(5);
    width: rem-calc(220);
    max-width: 100%;
    margin: 0 0 10px 10px;
    background-color: rgba($black, 0.6);
    @include breakpoint(large) {
      width: rem-calc(300);
      margin: 0 0 20px 20px;
      padding: rem-calc(5 8 8 8);
    }
    p {
      margin: 0;
      line-height: 1.2;
    }
  }
}

@include breakpoint(medium) {
  .orbit.h100 {
    height:100%;
    .orbit-wrapper {
      height:100%;
      .orbit-container {
        height:100% !important;
        .orbit-slide {
          height:100%;
          figure {
            height:100%;
            img {
              height:100%;
            }
          }
        }
      }
    }
  }
}