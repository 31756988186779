section {
  padding: rem-calc(0 0 10 0);
  @include breakpoint (medium) {
    padding: rem-calc(0 0 30 0);
  }
  @include breakpoint (large) {
    padding: rem-calc(0 0 50 0);
  }
  &#kontakt {
    background-color: $blue;
    padding: 5em 0;
    color: $white;
    position: relative;
    z-index: 10;
  }
  &.nopadding {
    padding: 0;
  }

  &:last-child {
    padding: 0;
  }
}
