#main-menu {
  font-size: rem-calc(30);
  text-transform: uppercase;
  margin-top: 8vh;
  @include breakpoint(medium) {
    margin-top: 15vh;
    font-size: rem-calc(45);
  }
  @include breakpoint(large) {
    margin-top: 20vh;
  }
  .menu {
    li {
      padding: rem-calc(5 0);
      a {
        color: $white;
        outline: none;
        padding: 1rem 0 0.5rem 0;
      }
      &.active {
        a {
          border-bottom: 3px solid $white;
          background-color: transparent;
        }
      }
    }
  }
}
