$form-input-border-radius: 0.1em;
$form-transition: all 0.3s ease-in-out;
$radio-diameter-outer: 1em; // to center, you halso have to change transform on the radio/checkbox input
$radio-diameter-inner: 0.5em;
$radio-background-hover: rgba($black, 1);
$radio-background-focus: rgba(blue, 0.2);
// overwrite other variables (like $input-border) in settings

form {
  label {
    color: $form-label-color;
    font-size: $input-font-size;
    font-family: $input-font-family;
    line-height: $input-line-height;
  }

  input[type="radio"],
  input[type="checkbox"] {
    cursor: pointer;
    transform: translateY(0.2em);
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: transparent;
    /* Not removed via appearance */
    margin: 0;
    color: $input-color;
    width: $radio-diameter-outer;
    height: $radio-diameter-outer;
    border: 0.15em solid $input-border-color;
    border-radius: 100%;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: $radio-diameter-inner;
      height: $radio-diameter-inner;
      border-radius: 100%;
      transform: scale(0) translate(0, 0); // have to set translate(0, 0) so transition works (otherwise there is not "starting translate"
      transition: $form-transition;
      background: $input-border-color-focus;
    }

    &:checked::before {
      transform: scale(1) translate(-50%, -50%);
    }

    &:focus {
      box-shadow: 0 0 0 0.2em $radio-background-focus;
    }
  }

  .input-container {
    position: relative;
    margin-bottom: 1.2em;

    input,
    textarea {
      color: $input-color;
      font-size: $input-font-size;
      font-family: $input-font-family;
      line-height: $input-line-height;
      background: $input-background;

      &:focus {
        background: $input-background-focus;

        & + label {
          border: $input-border-focus;
          box-shadow: $input-shadow-focus;
          color: $input-border-color-focus;

          span {
            background: $input-background-focus;
          }
        }
      }

      &:focus,
      &.not-empty {
        & + label span {
          transform: translateY(-50%);
          top: 0;
          font-size: 70%;
          line-height: 1;
          padding: 0 calc($input-padding/2);
          left: calc($input-padding/2);
        }
      }

      &:disabled + label {
        background: $input-background-disabled;
      }

      &.is-invalid-input {
        color: $input-error-color;

        &:focus + label,
        &.not-empty + label,
        & + label {
          color: $form-label-color-invalid;
          border-color: $alert-color;
        }
      }
    }

    label {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      border: $input-border;
      border-radius: $input-radius;
      pointer-events: none;
      box-shadow: $input-shadow;

      span {
        position: absolute;
        top: $input-padding;
        left: $input-padding;
        display: inline-block;
        transform: translateY(0);
        transition: $form-transition;
        background-color: $input-background;
      }

      abbr,
      abbr[title] {
        border-bottom: none;
      }
    }

    span.form-error {
      position: absolute;
      margin: 0;
      bottom: -5px;
      left: $input-padding;
      color: $alert-color;
      font-size: 0.7 * $input-font-size;
      line-height: 1;
      transform: translateY(100%);
    }
  }

  fieldset {
    ul {
      list-style-type: none;
      margin-left: 0;

      li {
        input {
          &[type="text"] {
            width: auto;
            display: inline-block;
          }

          &[type="radio"],
          &[type="checkbox"] {
            &.is-invalid-input {
              border-color: $alert-color;
            }
          }
        }
      }
    }
  }

  .form-error {
    text-align: right;
    font-size: $input-font-size;
    margin-top: 1em;

    span {
      max-width: 70ch;
      color: $alert-color;
    }
  }

  button[type="submit"] {
    display: block;
    margin-left: auto;
  }
}


////von Leumatt
.form-contact {
  label {
    font-size: rem-calc(16);
    font-weight: normal;
    @include breakpoint(medium) {
      font-size: rem-calc(19);
    }
    @include breakpoint(large) {
      font-size: rem-calc(22);
    }
  }
  label[for="herr"] {
    margin-right: rem-calc(30);
  }
  .label-cell {
    display: block;//labels untereinander positionieren
  }
  .label-m-bottom {
    margin-bottom: rem-calc(15)!important;
    @include breakpoint(medium) {
      margin-bottom: rem-calc(10)!important;
    }
  }
  .p-top-bottom-medium-up {
    @include breakpoint(medium) {
      padding-top: rem-calc(50);
      padding-bottom: rem-calc(50);
    }
  }
  .m-bottom-small {
    margin-bottom: rem-calc(30);
  }
  .c-submit {
    margin-top: rem-calc(30);
  }
  .error {
    font-size: 0;
    .error {
      color: $black;//red//
      font-size: .7rem;
      @include breakpoint(medium) {
        font-size: .9rem;
      }
    }
  }
}


.form-contact {

  /* Hide the native radio and checkbox buttons */
  input[type="checkbox"],
  input[type="radio"] {
    display: none;
  }

  /* override default styles from foundation */
  input[type="checkbox"] + label,
  input[type="radio"] + label {
    margin-left: 0;
  }

  input[type="checkbox"] + label .checkbox,
  input[type="radio"] + label .radio {
    cursor: pointer;
    position: absolute;
  }

  /* text for label: (offset) margin-left, because of multiline-text! */
  input[type="checkbox"] + label .txt,
  input[type="radio"] + label .txt {
    display: block;
    margin-left: 45px;
    line-height: 1.3;
  }

  /* custom error for chekboxes */
  input[type="checkbox"] + label > .error .error {
    margin-left: 45px;
  }

  /* radio-buttons */
  input[type="radio"] + label .radio {
    background-color: $blue;
    border: 2px solid $white;
    @include box(28px, 28px, true);
  }
  /* When the radio is checked (we could add a border, shadow, etc) */
  input[type="radio"]:checked + label .radio {
    &::before {
      content: '';
      display: inline-block;
      margin: 0 auto;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      background-color: $white;
      transform: translateY(-50%);
      @include box(14px, 14px, true);//half of span-width 28px
    }
  }

  /* checkbox-buttons */
  input[type="checkbox"] + label .checkbox {
    background-color: $blue;
    border: 2px solid $white;
    width: 28px;
    height: 28px;
  }

  /* When the checkbox is checked (we could add a border, shadow, etc) */
  input[type="checkbox"]:checked + label .checkbox {
    &::before {
      content: '';
      display: inline-block;
      margin: 0 auto;
      position: absolute;
      left: 0;
      right: 0;

      top: 7px;
      width: 17px;
      height: 10px;
      //color: $green;
      border-left: 3px solid;
      border-bottom: 3px solid;
      transform: rotate(-45deg);
    }
  }

}

.form-block {
  margin-bottom: rem-calc(30);
}

::placeholder {
  opacity: 1;
}
