.logo {
  padding: rem-calc(30 20 20 20);
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: 1;
  @include breakpoint(medium) {
    padding: rem-calc(80 20 20 20);
  }
  @include breakpoint(large) {
    padding: rem-calc(120 20 20 20);
  }
  @include breakpoint(xlarge) {
    padding: rem-calc(150 20 20 20);
  }
  img {
    width: auto;
    height: rem-calc(60); //immer width und height in PX angeben (IE)
    max-width: none;
    @include breakpoint(medium) {
      height: rem-calc(90);
    }
    @include breakpoint(large) {
      height: rem-calc(140);
    }
    @include breakpoint(xlarge) {
      height: rem-calc(165);
    }
  }
  &.logo-haus {
    text-align: center;
    .logo-wrap {
      border-bottom: 2px solid $white;
      margin-bottom: 2em;
      display: inline-block;
      img {
        height: rem-calc(50);
        margin-bottom: 2.3em;
        @include breakpoint(medium) {
          height: rem-calc(70);
        }
        @include breakpoint(large) {
          height: rem-calc(110);
        }
        @include breakpoint(xlarge) {
          height: rem-calc(140);
        }
      }
    }
    h1 {
      @include font-size(1.25,1.5625);
      color: $white;
      text-transform: uppercase;
    }
  }
}

